// main.js - initialize application
//
// Bootstrap Vue
//
import VeeValidate from 'vee-validate'
import Vue from 'vue'
import VueFeather from 'vue-feather'
import VueRouter from 'vue-router'
import { BootstrapVue, BootstrapVueIcons }  from 'bootstrap-vue'
import axios from 'axios'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Logging
//
import VueLogger from 'vuejs-logger'

// Authentication
//
import Keycloak from 'keycloak-js'

// Vuex
//
import store from './store'

// Routes for Vue Router
//
import routes from './routes'

// Portal app
//
import App from './App.vue'

const vvConfigs = {
  //errorBagName: 'errorBags',
  fieldsBagName: 'fieldBags',
};

Vue.use(VeeValidate, vvConfigs)
Vue.use(VueFeather)
Vue.use(VueRouter)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

// Set up logging
//
const isProduction = process.env.NODE_ENV === 'production';
const options = {
  isEnabled: true,
  logLevel : isProduction ? 'error' : 'debug',
  stringifyArguments : false,
  showLogLevel : true,
  showMethodName : true,
  separator: '|',
  showConsoleColors: true
};
Vue.use(VueLogger, options);

function displayLoginError() {
  document.getElementById("app").innerHTML += '<p>Authentication failed.</p><p><a href="javascript:window.location.reload()">RETRY</a></p>'
}

function displayInitError() {
  document.getElementById("app").innerHTML += '<p>Initialization failed.</p><p><a href="javascript:window.location.reload()">RETRY</a></p>'
}

Vue.$log.debug("Fetching configs.json")
axios.get(`${process.env.BASE_URL}configs.json?v=${Date.now()}`)
.then(response => {
  var configs = response.data
  Vue.$log.info("Loaded configs.json");
  Vue.$log.debug(configs);
  Vue.prototype.$configs = configs

  // Set up router
  //
  const router = new VueRouter({routes})

  // Handle authentication steps.
  //
  if ((configs.authUrl !== undefined) && (configs.authUrl != ''))
  {
    var onLoad = "login-required"
    var keycloakOptions = {
      "url": configs.authUrl,
      "realm": configs.authRealm,
      "clientId": configs.authClientId
    }
    let keycloak = new Keycloak(keycloakOptions);
    keycloak.init({ 
      "onLoad": onLoad,
      "checkLoginIframe": false
    })
    .then(async (authenticated ) => {
      if (!authenticated ) {
        Vue.$log.warn("NOT AUTHENTICATED");
        displayLoginError()
      }
      else {
        Vue.$log.info("Authenticated");
        store.commit("changeKeycloak", keycloak)
        Vue.$log.debug(keycloak.tokenParsed)
        var profile = { 
          username: keycloak.tokenParsed.preferred_username,
          firstName: keycloak.tokenParsed.given_name,
          lastName: keycloak.tokenParsed.family_name
        }
        store.commit("changeUserProfile", profile);

        let opts = {
          headers: {
              Authorization: 'Bearer '+keycloak.token
          }
        }
        axios.get(configs.endpointsBaseUrl+'/UserSettings', opts)
        .then(response => {
          // Save settings
          //
          let user_settings = response.data.user_settings || {};
          store.commit("changeUserSettings", user_settings);

          // Launch app
          //
          Vue.$log.debug("Starting app...")
          new Vue({
            el: '#app',
            router,
            store,
            render: h => h(App)
          })
        })
        .catch(err => {
          Vue.$log.error("Error fetching user settings from server: "+err.message)
          displayInitError()
        })

        // Token Refresh
        //
        setInterval(() => {
          keycloak.updateToken(70).then((refreshed) => {
            Vue.$log.info('Token refreshed: ' + refreshed);
          }).catch(() => {
            // Handle session timeout or other token errors
            //
            Vue.$log.error('Failed to refresh token');
          });
        }, 6000)
      }
    })
    .catch(() => {
      Vue.$log.error("Authentication Failed");
      displayLoginError()
    });
  }
  else
  {
    Vue.$log.warn("Authentication not configured");
    displayLoginError()
  }
})
.catch((error) => {
  Vue.$log.error(`Error getting ${process.env.BASE_URL+"configs.json"}: ${error.message}`);
});
